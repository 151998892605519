import React from 'react';
// Components
import CardSection from '../CardSection';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
// Redux
import { useSelector } from 'react-redux';
// Services
import { getSectionsInstitutions } from '../../services/getSectionsInstitutions';
// Styles
import './CarouselSectionsHome.css';

const CarouselSectionsHome = () => {
  const settings = {
    autoPlay: true,
    infinite: true,
    autoPlayInterval: 8000,
    animationDuration: 800,
    disableButtonsControls: false,
    disableDotsControls: true,
    keyboardNavigation: true,
    touchMoveDefaultEvents: true,
    showArrows: true,
    responsive: {
      0: {
        items: 1,
      },
      650: {
        items: 2,
        itemsFit: 'contain',
      },
      950: {
        items: 3,
        itemsFit: 'contain',
      },
      1350: {
        items: 4,
        itemsFit: 'contain',
      },
      1600: {
        items: 5,
        itemsFit: 'contain',
      },
    },
  };
  const { institutionCode, ebooksAccess } = useSelector(
    (state) => state.institution
  );
  const dataMetasearcher = getSectionsInstitutions(
    institutionCode,
    ebooksAccess
  )[0].data;
  const dataIndependient = getSectionsInstitutions(
    institutionCode,
    ebooksAccess
  )[1].data;
  const data = [...dataMetasearcher, ...dataIndependient];
  const filteredSections = data.filter((section) => section.url !== '');

  return (
    <AliceCarousel {...settings}>
      {filteredSections?.map((section, i) => (
        <CardSection
          key={i}
          title={section.title}
          url={section.url}
          route={section.route}
        />
      ))}
    </AliceCarousel>
  );
};

export default CarouselSectionsHome;
