import React, { useState } from 'react';

import Loading from '../Loading';
// Icons
import { IoClose } from 'react-icons/io5';
import { GoCheckCircle } from 'react-icons/go';
import { BiQuestionMark } from 'react-icons/bi';
import { MdError } from 'react-icons/md';
// Services
import { sendChatInformation } from '../../services/sendChatInformation';
//Style
import './Chat.css';

const Chat = () => {
  const [active, setActive] = useState(false);

  const handleActiveChat = () => {
    setActive(!active);
  };

  const emptyData = {
    phone: '',
    fullName: '',
    email: '',
    title: '',
    description: '',
  };

  // Form States
  const [status, setStatus] = useState('pending');
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [urgency, setUrgency] = useState('');
  const [institution, setInstitution] = useState('');
  const [contactInfo, setContactInfo] = useState(emptyData);

  // Manejador de cambio para los select
  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    if (name === 'Type') setType(value);
    if (name === 'Urgency') setUrgency(value);
  };

  // Manejador de cambio para los inputs de texto
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setContactInfo({
      ...contactInfo,
      [name]: value,
    });
  };

  // Manejo del envío del formulario
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const to_email = ['librarian@aquinasnetwork.com'];

    sendChatInformation(contactInfo, institution, type, urgency, to_email).then(
      (response) => {
        if (response?.status === 201) {
          setStatus('done');
          setTimeout(() => {
            setLoading(false);
            setStatus('pending');
            // Reset form
            setType('');
            setUrgency('');
            setInstitution('');
            setContactInfo(emptyData);
          }, 3000);
        } else {
          setStatus('error');
          setTimeout(() => {
            setLoading(false);
            setStatus('pending');
          }, 3000);
        }
      }
    );
  };

  if (!active) {
    return (
      <div
        role='button'
        className='chatPosition chatDeactivate'
        onClick={handleActiveChat}
      >
        <p>Do you have any question or suggestions?</p>
        <BiQuestionMark />
      </div>
    );
  } else {
    if (status === 'done') {
      return (
        <div className='chatPosition chatActivate success'>
          <button>
            <IoClose className='closeModal' onClick={handleActiveChat} />
          </button>

          <GoCheckCircle className='successIcon' />
          <p className='principalTextSuccess'>
            Thank you! Your ticket has been successfully created.
          </p>
          <p className='auxiliaryTextSuccess'>
            We are working on your request and it will be addressed in the next
            few hours. We’ll get back to you as soon as possible.
          </p>
        </div>
      );
    } else if (status === 'error') {
      return (
        <div className='chatPosition chatActivate success'>
          <button>
            <IoClose className='closeModal' onClick={handleActiveChat} />
          </button>

          <MdError className='errorIcon' />
          <p className='principalTextSuccess'>Oops! A problem has occurred</p>
          <p className='auxiliaryTextSuccess'>
            Please try submitting your application again.
          </p>
        </div>
      );
    } else {
      return (
        <form
          action=''
          method='POST'
          className='chatPosition chatActivate'
          onSubmit={handleSubmit}
        >
          <button>
            <IoClose className='closeModal' onClick={handleActiveChat} />
          </button>
          <p className='principalText'>
            Need a librarian's advice or is something not working?
          </p>
          <p className='auxiliaryText'>We're here to help!</p>

          <select
            name='Type'
            id='type'
            value={type}
            onChange={handleSelectChange}
            aria-label='Select type'
            required
          >
            <option value='' disabled hidden>
              Type
            </option>
            <option value='Technician'>Technician</option>
            <option value='Librarian'>Librarian</option>
          </select>
          <select
            name='Urgency'
            id='urgency'
            value={urgency}
            onChange={handleSelectChange}
            aria-label='Select urgency'
            required
          >
            <option value='' disabled hidden>
              Urgency
            </option>
            <option value='High'>High</option>
            <option value='Medium'>Medium</option>
            <option value='Low'>Low</option>
          </select>
          <input
            type='text'
            id='institution'
            name='institution'
            placeholder='Institution name'
            value={institution}
            onChange={(e) => setInstitution(e.target.value)}
            aria-label='Institution name'
            required
          />
          <label>
            <b>Contact information:</b>
          </label>
          <div className='contactInfo'>
            <input
              type='tel'
              id='phone'
              name='phone'
              placeholder='Phone'
              value={contactInfo.phone}
              onChange={handleInputChange}
              aria-label='Phone number'
              required
            />
            <input
              type='text'
              id='fullName'
              name='fullName'
              placeholder='Full Name'
              value={contactInfo.fullName}
              onChange={handleInputChange}
              aria-label='Full Name'
              required
            />
          </div>
          <input
            type='email'
            id='email'
            name='email'
            placeholder='Email'
            value={contactInfo.email}
            onChange={handleInputChange}
            aria-label='Email address'
            required
          />
          <input
            type='text'
            id='title'
            name='title'
            placeholder='Subject'
            value={contactInfo.title}
            onChange={handleInputChange}
            aria-label='Title'
            required
          />
          <textarea
            id='description'
            name='description'
            placeholder='Description'
            value={contactInfo.description}
            onChange={handleInputChange}
            aria-label='Description of the issue'
            required
          />
          {loading ? (
            <div className='loadingForm'>
              <Loading color='green' />
            </div>
          ) : (
            <button className='submitButton' type='submit'>
              Create a Ticket
            </button>
          )}
        </form>
      );
    }
  }
};

export default Chat;
