// Utils
import { fetchWithoutToken } from '../utils/fetch';

// Function to get Validate Security Code
export const getRecoverPassword = async (email) => {
  try {
    const res = await fetchWithoutToken.post(
      `/users/recover-password?email=${email}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// Function to get Validate Security Code
export const getValidateSecurityCode = async (email, code) => {
  try {
    const res = await fetchWithoutToken.post(
      `/users/validate-security-code?email=${email}&code=${code}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
    return error;
  }
};

// Function to change the password
export const getNewPassword = async (email, security_code, new_password) => {
  try {
    const res = await fetchWithoutToken.post(
      `/users/new-password`,
      {
        email,
        security_code,
        new_password,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    );
    return res;
  } catch (error) {
    console.error(error);
    return error;
  }
};
