import React, { useState } from 'react';
// Reddux
import { useSelector } from 'react-redux';
// Hooks
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Components
import Container from '../Container';
// Utils
import { removeElement } from '../../utils/removeElement';
// Icons
import { IoMdOptions } from 'react-icons/io';
import { TbChevronUp } from 'react-icons/tb';
import { IoMdClose } from 'react-icons/io';
// Styles
import './FiltersInterestingLinks.css';

const FiltersInterestingLinks = ({ selected, setSelected }) => {
  const { handleScrollToTop } = useScrollToTop();

  const { interestingLinks } = useSelector((state) => state.interestingLinks);
  const programsList = interestingLinks.map((objeto) => objeto.program);
  const programs = [...new Set(programsList)];

  // Variables para visualización de filtros
  const [isOpenSource, setIsOpenSource] = useState(true);
  const [activeFilter, setActiveFilter] = useState(false);

  const { institutionCode } = useSelector((state) => state.institution);

  const handleSelectSource = (source) => {
    handleScrollToTop(0);
    if (selected.includes(source)) {
      setSelected(selected.filter((value) => value !== source));
    } else {
      setSelected([...selected, source]);
    }
  };

  const handleClearSources = () => {
    handleScrollToTop(0);
    setSelected([]);
  };

  const handleRemoveSource = (source) => {
    handleScrollToTop(0);
    setSelected(removeElement(selected, source));
  };

  return (
    <>
      <div className='filtersBarIconNews'>
        <b>Filters</b>
        <button onClick={() => setActiveFilter(true)}>
          <IoMdOptions className='optionsIcon' />
        </button>
      </div>

      <section
        className={
          activeFilter
            ? 'sectionFilterNews activeFilterNews'
            : 'sectionFilterNews'
        }
      >
        <div
          onClick={() => setActiveFilter(false)}
          className={
            activeFilter
              ? 'filterBarBackgroundNews activeFilterNews'
              : 'filterBarBackgroundNews'
          }
        ></div>
        <div className='filtersBarNews'>
          <Container className='filtersBarMainNews'>
            <button onClick={() => setActiveFilter(false)}>
              <IoMdClose />
            </button>
            <div>
              <IoMdOptions className='optionsIcon' />
              <h2>Filters</h2>
            </div>
          </Container>

          {selected?.length > 0 && (
            <Container className='filtersBarSectionNews'>
              <div className='filtersBarSection__main'>
                <h3>Filters</h3>
                <button onClick={handleClearSources}>Remove all filters</button>
              </div>
              <div className='filtersBarSectionFilter__content'>
                {selected?.length > 0 &&
                  selected?.map((source, i) => (
                    <button key={i} onClick={() => handleRemoveSource(source)}>
                      {source} <IoMdClose />
                    </button>
                  ))}
              </div>
            </Container>
          )}

          <Container className='filtersBarSectionNews'>
            <div className='filtersBarSection__main'>
              <h3>
                {institutionCode === 'ILUNI' ? 'Bases de datos' : 'Sources'}
              </h3>

              <button onClick={() => setIsOpenSource(!isOpenSource)}>
                <TbChevronUp className={isOpenSource ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenSource && (
              <div className='filtersBarSection__content fixed'>
                {programs?.map((program, i) => {
                  return (
                    <button
                      key={i}
                      className={selected.includes(program) ? 'selected' : ''}
                      onClick={() => handleSelectSource(program)}
                    >
                      {`${program}`}
                    </button>
                  );
                })}
              </div>
            )}
          </Container>
        </div>
      </section>
    </>
  );
};

export default FiltersInterestingLinks;
