import React, { useState } from 'react';
// Components
import Container from '../Container';
import MultiRangeSlider from '../MultiRangeSlider';
// Utils
import { areListsDiff } from '../../utils/areaListDiff';
import { removeElement, removeListFromList } from '../../utils/removeElement';
// Reddux
import { useSelector, useDispatch } from 'react-redux';
// Hooks
import { useBooks } from '../../hooks/useBooks';
import { useScrollToTop } from '../../hooks/useScrollToTop';
// Slices
import {
  setStartYear,
  setEndYear,
  setSelectedSources,
  resetFilters,
  setPageShowed,
  setAuthors,
  setTopic,
  setLanguage,
} from '../../app/features/booksSlice';
// Icons
import { IoMdOptions } from 'react-icons/io';
import { TbChevronUp } from 'react-icons/tb';
import { IoMdClose } from 'react-icons/io';
import { IoMdSearch } from 'react-icons/io';

// Styles
import './FiltersBooks.css';

const FiltersBooks = () => {
  const dispatch = useDispatch();

  const { handleScrollToTop } = useScrollToTop();
  const { getBooksDataSocket } = useBooks();

  // Variables para visualización de filtros
  const [isOpenSource, setIsOpenSource] = useState(false);
  const [isOpenYears, setIsOpenYears] = useState(false);
  const [isOpenAuthor, setIsOpenAuthor] = useState(false);
  const [isOpenTheme, setIsOpenTheme] = useState(false);
  const [isOpenLanguage, setIsOpenLanguage] = useState(false);

  const [activeFilter, setActiveFilter] = useState(false);
  const [errorFilterMessage, setErrorFilterMessage] = useState('');

  // List of databases that do not allow filtering by language or authors
  const noLanguageList = ['OAPEN', 'EUR', 'DOAB'];
  const noAuthorList = ['OTL', 'OAPEN', 'DOAB'];

  // Función que cambia el estado de las variables startYear y endYear
  const yearSelected = (startYear, endYear) => {
    dispatch(setStartYear(startYear));
    dispatch(setEndYear(endYear));
  };

  const {
    selectedSources,
    startYear,
    endYear,
    totalBooks,
    filters,
    allBooksSources,
    authors,
    topic,
    language,
  } = useSelector((state) => state.books);

  const { institutionCode } = useSelector((state) => state.institution);

  // Función que selecciona las bases de datos
  const sourcesSelected = (optionValue) => {
    if (selectedSources.includes(optionValue)) {
      dispatch(
        setSelectedSources(
          selectedSources.filter((value) => value !== optionValue)
        )
      );
    } else {
      dispatch(setSelectedSources([...selectedSources, optionValue]));
    }
  };

  // Botón de Aplicar filtros
  const handleApplyFilters = (
    start,
    end,
    sources,
    authors,
    topic,
    language
  ) => {
    let process = true;
    let sourcesToSearch = sources;

    if (language === 'es') {
      sourcesToSearch = removeListFromList(sourcesToSearch, noLanguageList);
      process = sourcesToSearch.length > 0;
    }
    if (authors.length > 0) {
      sourcesToSearch = removeListFromList(sourcesToSearch, noAuthorList);
      process = sourcesToSearch.length > 0;
    }

    if (process) {
      handleScrollToTop(0);
      dispatch(setPageShowed(1));
      getBooksDataSocket(
        filters.queryFetched,
        1,
        start,
        end,
        sourcesToSearch,
        [],
        authors,
        topic,
        language
      );
    } else {
      setErrorFilterMessage(
        'This data source does not support this type of filter.'
      );
      setTimeout(() => {
        setErrorFilterMessage('');
      }, 3000);
    }
  };

  // Funcion para Borrar filtros
  const handleClearFilters = () => {
    handleScrollToTop(0);
    dispatch(resetFilters());
    getBooksDataSocket(
      filters.queryFetched,
      1,
      1990,
      2024,
      allBooksSources,
      [],
      '',
      '',
      'en'
    );
  };

  return (
    <>
      <div className='filtersBarIcon'>
        <b>Filters</b>
        <button onClick={() => setActiveFilter(true)}>
          <IoMdOptions className='optionsIcon' />
        </button>
      </div>

      <section
        className={
          activeFilter ? 'sectionFilter activeFilter' : 'sectionFilter'
        }
      >
        <div
          onClick={() => setActiveFilter(false)}
          className={
            activeFilter
              ? 'filterBarBackground activeFilter'
              : 'filterBarBackground'
          }
        ></div>
        <div className='filtersBar'>
          <Container className='filtersBarMain'>
            <button onClick={() => setActiveFilter(false)}>
              <IoMdClose />
            </button>
            <div>
              <IoMdOptions className='optionsIcon' />
              <h2>Filters</h2>
            </div>
          </Container>

          {(filters?.endYearFetched !== 2024 ||
            filters?.startYearFetched !== 1990 ||
            filters?.authorsFetched !== '' ||
            filters?.topicFetched !== '' ||
            filters?.languageFetched !== 'en' ||
            areListsDiff(filters?.sourcesFetched, allBooksSources)) && (
            <Container className='filtersBarSection'>
              <div className='filtersBarSection__main'>
                <h3>Filters</h3>
                <button onClick={handleClearFilters}>Remove all filters</button>
              </div>
              <div className='filtersBarSectionFilter__content'>
                {filters?.authorsFetched !== '' && (
                  <button
                    onClick={() =>
                      handleApplyFilters(
                        startYear,
                        endYear,
                        selectedSources,
                        '',
                        topic,
                        language
                      )
                    }
                  >
                    {`Authors: ${filters?.authorsFetched}`} <IoMdClose />
                  </button>
                )}
                {filters?.topicFetched !== '' && (
                  <button
                    onClick={() =>
                      handleApplyFilters(
                        startYear,
                        endYear,
                        selectedSources,
                        authors,
                        '',
                        language
                      )
                    }
                  >
                    {`Topic: ${filters?.topicFetched}`} <IoMdClose />
                  </button>
                )}
                {filters?.languageFetched !== 'en' && (
                  <button
                    onClick={() =>
                      handleApplyFilters(
                        startYear,
                        endYear,
                        selectedSources,
                        authors,
                        topic,
                        'en'
                      )
                    }
                  >
                    {`Language: ${
                      filters?.languageFetched === 'en' ? 'English' : 'Spanish'
                    }`}
                    <IoMdClose />
                  </button>
                )}
                {filters?.startYearFetched !== 1990 && (
                  <button
                    onClick={() =>
                      handleApplyFilters(
                        1990,
                        endYear,
                        selectedSources,
                        authors,
                        topic,
                        language
                      )
                    }
                  >
                    {`Start year: ${filters?.startYearFetched}`} <IoMdClose />
                  </button>
                )}

                {filters?.endYearFetched !== 2024 && (
                  <button
                    onClick={() =>
                      handleApplyFilters(
                        startYear,
                        2024,
                        selectedSources,
                        authors,
                        topic,
                        language
                      )
                    }
                  >
                    {`End year: ${filters?.endYearFetched}`}
                    <IoMdClose />
                  </button>
                )}

                {areListsDiff(filters?.sourcesFetched, allBooksSources) &&
                  filters.sourcesFetched?.map((source, i) => (
                    <button
                      key={i}
                      onClick={() =>
                        handleApplyFilters(
                          startYear,
                          endYear,
                          removeElement(selectedSources, source).length > 0
                            ? removeElement(selectedSources, source)
                            : allBooksSources,
                          authors,
                          topic,
                          language
                        )
                      }
                    >
                      {source.replace('_', ' ')} <IoMdClose />
                    </button>
                  ))}
              </div>
            </Container>
          )}

          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenSource(!isOpenSource)}
              role='button'
            >
              <h3>
                {institutionCode === 'ILUNI' ? 'Bases de datos' : 'Sources'}
              </h3>

              <button>
                <TbChevronUp className={isOpenSource ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenSource && (
              <div className='filtersBarSection__content fixed'>
                {allBooksSources?.map((source, i) => {
                  return (
                    <button
                      key={i}
                      className={
                        selectedSources.includes(source) ? 'selected' : ''
                      }
                      onClick={() => sourcesSelected(source)}
                    >
                      {source.replace('_', ' ')}
                    </button>
                  );
                })}
              </div>
            )}
          </Container>

          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenYears(!isOpenYears)}
              role='button'
            >
              <h3>{institutionCode === 'ILUNI' ? 'Año' : 'Year'}</h3>
              <button>
                <TbChevronUp className={isOpenYears ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenYears && (
              <div className='filtersBarSection__content'>
                <MultiRangeSlider
                  min={startYear}
                  max={endYear}
                  onChange={({ min, max }) => yearSelected(min, max)}
                />
              </div>
            )}
          </Container>

          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenAuthor(!isOpenAuthor)}
              role='button'
            >
              <h3>{institutionCode === 'ILUNI' ? 'Autor' : 'Author'}</h3>

              <button>
                <TbChevronUp className={isOpenAuthor ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenAuthor && (
              <div className='filtersBarSection__content fixed'>
                <div className='inputFilterContainer'>
                  <input
                    className='inputFilter'
                    placeholder='Search for authors'
                    value={authors}
                    onChange={(e) => dispatch(setAuthors(e.target.value))}
                  />
                  <button
                    className='inputFilterButton'
                    onClick={() =>
                      handleApplyFilters(
                        startYear,
                        endYear,
                        selectedSources,
                        authors,
                        topic,
                        language
                      )
                    }
                  >
                    <IoMdSearch />
                  </button>
                </div>
              </div>
            )}
          </Container>
          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenTheme(!isOpenTheme)}
              role='button'
            >
              <h3>{institutionCode === 'ILUNI' ? 'Tema' : 'Topic'}</h3>

              <button>
                <TbChevronUp className={isOpenTheme ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenTheme && (
              <div className='filtersBarSection__content fixed'>
                <div className='inputFilterContainer'>
                  <input
                    className='inputFilter'
                    placeholder='Search for topic'
                    value={topic}
                    onChange={(e) => dispatch(setTopic(e.target.value))}
                  />
                  <button
                    className='inputFilterButton'
                    onClick={() =>
                      handleApplyFilters(
                        startYear,
                        endYear,
                        selectedSources,
                        authors,
                        topic,
                        language
                      )
                    }
                  >
                    <IoMdSearch />
                  </button>
                </div>
              </div>
            )}
          </Container>

          <Container className='filtersBarSection'>
            <div
              className='filtersBarSection__main'
              onClick={() => setIsOpenLanguage(!isOpenLanguage)}
              role='button'
            >
              <h3>{institutionCode === 'ILUNI' ? 'Idioma' : 'Language'}</h3>

              <button>
                <TbChevronUp className={isOpenLanguage ? '' : 'iconActive'} />
              </button>
            </div>
            {isOpenLanguage && (
              <div className='filtersBarSection__content fixed language'>
                <label className='labelLanguage'>
                  English
                  <input
                    checked={language === 'en' ? true : false}
                    className='labelBox'
                    type='checkbox'
                    onChange={() => dispatch(setLanguage('en'))}
                  />
                </label>
                <label className='labelLanguage'>
                  Spanish
                  <input
                    checked={language === 'es' ? true : false}
                    className='labelBox'
                    type='checkbox'
                    onChange={() => dispatch(setLanguage('es'))}
                  />
                </label>
              </div>
            )}
          </Container>

          {(startYear !== filters.startYearFetched ||
            endYear !== filters.endYearFetched ||
            authors !== filters?.authorsFetched ||
            topic !== filters?.topicFetched ||
            language !== filters?.languageFetched ||
            areListsDiff(selectedSources, filters.sourcesFetched)) &&
            totalBooks > 0 && (
              <Container>
                <button
                  onClick={() =>
                    handleApplyFilters(
                      startYear,
                      endYear,
                      selectedSources,
                      authors,
                      topic,
                      language
                    )
                  }
                  className='applyButton'
                >
                  Apply filters
                </button>
              </Container>
            )}

          {errorFilterMessage.length > 0 && (
            <Container>
              <p className='errorMessageFilter'>{errorFilterMessage}</p>
            </Container>
          )}
        </div>
      </section>
    </>
  );
};

export default FiltersBooks;
