import React from 'react';
// Components
import Container from '../../Components/Container';
import CardJobs from '../../Components/CardJobs';
import JobDetail from '../../Components/JobDetail';
import { LoadingSkeleton } from '../../Components/LoadingSkeleton';
import { SkeletonTypes } from '../../Components/LoadingSkeleton/types';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Slices
import {
  setJobs,
  setIsLoadingJobs,
  setIsLoadingMoreJobs,
  searchNewData,
  setMoreDataAvailable,
  setPageJobs,
  setQueryJobsFetched,
  setRemoteJob,
  setNextPage,
} from '../../app/features/jobsSlice';
// Services
import { getJobs } from '../../services/getJobs';
// Styles
import './JobsPage.css';

const JobsPage = () => {
  const dispatch = useDispatch();
  const {
    jobs,
    queryJobsFetched,
    isLoadingJobs,
    isLoadingMoreJobs,
    moreDataAvailable,
    pageJobs,
    remoteJob,
    jobFetched,
    next_page,
  } = useSelector((state) => state.jobs);
  const { institutionCode } = useSelector((state) => state.institution);

  const HandleGetJobs = (query, page = 1, only_remote = 0, next_page = '') => {
    if (query?.length > 0) {
      if (page === 1) {
        dispatch(setIsLoadingJobs(true));
        dispatch(searchNewData());
        dispatch(setRemoteJob(0));
      } else {
        dispatch(setIsLoadingMoreJobs(true));
      }
      getJobs(query, page, only_remote, institutionCode, next_page)
        .then((response) => {
          if (response.status === 200) {
            if (response?.data?.data && Array.isArray(response.data.data)) {
              dispatch(setNextPage(response.data.next_page));
              dispatch(setJobs(response.data.data));
              dispatch(setQueryJobsFetched(query));
              dispatch(setPageJobs(page));
              if (response?.data?.data?.length < 10) {
                dispatch(setMoreDataAvailable(false));
              }
              dispatch(setIsLoadingJobs(false));
              dispatch(setIsLoadingMoreJobs(false));
            } else {
              console.error('The response data is not valid or missing.');
            }
          } else {
            console.error(`Request error. HTTP status: ${response.status}`);
          }
        })
        .catch((error) => {
          console.error('Request error:', error);
        });
    }
  };

  const HandleApplyFilter = (remote) => {
    dispatch(setRemoteJob(remote));
    dispatch(setIsLoadingJobs(true));
    dispatch(searchNewData());
    getJobs(queryJobsFetched, 1, remote, institutionCode)
      .then((response) => {
        if (response.status === 200) {
          if (response?.data?.data && Array.isArray(response.data.data)) {
            dispatch(setJobs(response?.data?.data));
            dispatch(setPageJobs(1));
            if (response?.data?.data?.length < 10) {
              dispatch(setMoreDataAvailable(false));
            }
            dispatch(setIsLoadingJobs(false));
          } else {
            console.error('The response data is not valid or missing.');
          }
        } else {
          console.error(`Request error. HTTP status: ${response.status}`);
        }
      })
      .catch((error) => {
        console.error('Request error:', error);
      });
  };

  return (
    <main className='jobs'>
      {isLoadingJobs && (
        <div className='jobsContainer'>
          <div className=' scroll-container jobsList_resultsFilters'>
            {Array.from({ length: 10 }).map((_, i) => (
              <LoadingSkeleton key={i} type={SkeletonTypes.LIBRARY_CARD} />
            ))}
          </div>
        </div>
      )}

      {jobs?.length > 0 ? (
        <>
          <div className='jobsSummary'>
            <Container className='filters'>
              <p>Filter:</p>
              <select
                value={remoteJob}
                className='categoryBarJob'
                onChange={(e) => {
                  HandleApplyFilter(e.target.value);
                }}
              >
                <option disabled>What are you searching?</option>
                <option value={0}>In-Person</option>
                <option value={1}>Remote</option>
              </select>
            </Container>
          </div>
          <Container>
            <div className='jobsContainer'>
              <section
                className={
                  Object.keys(jobFetched)?.length === 0
                    ? 'jobsList'
                    : 'jobsList deactivate'
                }
              >
                <div className='scroll-container'>
                  <div className='scroll-content jobsList_results'>
                    {jobs?.map((job, i) => {
                      return (
                        <CardJobs
                          key={i}
                          id={job.job_id}
                          img={job.thumbnail}
                          title={job.title}
                          company={job.company_name}
                          description={job.description.substring(0, 100)}
                          salary={job.detected_extensions?.salary}
                          date={job.detected_extensions?.posted_at}
                          schedule_type={job.detected_extensions?.schedule_type}
                          job={job}
                          work_from_home={
                            job.detected_extensions?.work_from_home
                          }
                        />
                      );
                    })}
                    {isLoadingMoreJobs &&
                      Array.from({ length: 3 }).map((card, i) => (
                        <LoadingSkeleton
                          key={i + 999}
                          type={SkeletonTypes.LIBRARY_CARD}
                        />
                      ))}
                    {moreDataAvailable && !isLoadingMoreJobs && (
                      <p
                        className='showMoreJobs'
                        role='button'
                        onClick={() =>
                          HandleGetJobs(
                            queryJobsFetched,
                            pageJobs + 1,
                            remoteJob,
                            next_page
                          )
                        }
                      >
                        Show more jobs
                      </p>
                    )}
                  </div>
                </div>
              </section>
              <JobDetail />
            </div>
          </Container>
        </>
      ) : (
        !isLoadingJobs && (
          <div className='noDataContainerJobs'>
            <p>Please enter a search job term</p>
          </div>
        )
      )}
    </main>
  );
};

export default JobsPage;
