// IMAGE SECTIONS
import art from '../Images/HomePage/Sections/artSection.webp';
import books from '../Images/HomePage/Sections/booksSection.webp';
import eBooks from '../Images/HomePage/Sections/eBooksSection.webp';
import dictionary from '../Images/HomePage/Sections/dictionarySection.webp';
import thesaurus from '../Images/HomePage/Sections/thesaurusSection.webp';
import job from '../Images/HomePage/Sections/job.png';
import articles from '../Images/HomePage/Sections/articlesSection.webp';
import media from '../Images/HomePage/Sections/media.png';
import news from '../Images/HomePage/Sections/newsSection.webp';
import patents from '../Images/HomePage/Sections/patentsSection.webp';
import interestingLinks from '../Images/HomePage/Sections/interestingLinksSection.webp';
import softSkillsSection from '../Images/HomePage/Sections/softSkillsSection.webp';
import iaSection from '../Images/HomePage/Sections/iaSection.webp';
// ICONS SECTIONS
import artIcon from '../Images/Login/Modal/artIcon.svg';
import articlesIcon from '../Images/Login/Modal/articlesIcon.svg';
import dictionaryIcon from '../Images/Login/Modal/dictionaryIcon.svg';
import thesaurusIcon from '../Images/Login/Modal/thesaurusIcon.svg';
import patentsIcon from '../Images/Login/Modal/patentsIcon.svg';
import newsIcon from '../Images/Login/Modal/newsIcon.svg';
import jobsIcon from '../Images/Login/Modal/jobsIcon.svg';
import interestingLinksIcon from '../Images/Login/Modal/interestingLinksIcon.svg';
import americanLifeIcon from '../Images/Login/Modal/americanLifeIcon.svg';
import videoLibraryIcon from '../Images/Login/Modal/interestingLinksIcon.svg';
import instructionalVideosIcon from '../Images/Login/Modal/instructionalVideosIcon.svg';
import helpIcon from '../Images/Login/Modal/helpIcon.svg';
import ebookIcon from '../Images/Login/Modal/ebookIcon.svg';
import iaIcon from '../Images/Login/Modal/iaIcon.png';

// Icons section
export const getSectionsWithoutNavbar = () => {
  return ['', 'login', 'signup', 'recover', 'ebooks', 'ai'];
};

export const getSectionsInstitutions = (institutionCode, ebooks) => {
  let sections = [
    {
      type: 'metasearcher',
      data: [
        {
          title: 'Articles',
          titulo: 'Artículos',
          url: articles,
          route: 'articles',
          description: '16k+ library results',
          icon: articlesIcon,
          type: 'metasearcher',
        },
        {
          title: 'Dictionary',
          titulo: 'Diccionario',
          url: dictionary,
          route: 'dictionary',
          description: 'More than 20k words from the dictionary',
          icon: dictionaryIcon,
          type: 'metasearcher',
        },
        {
          title: 'Thesaurus',
          titulo: 'Thesaurus',
          url: thesaurus,
          route: 'thesaurus',
          description: 'Thesaurus dictionary',
          icon: thesaurusIcon,
          type: 'metasearcher',
        },
        {
          title: 'Patents',
          titulo: 'Patentes',
          url: patents,
          route: 'patents',
          description: 'More than 20k Patent results',
          icon: patentsIcon,
          type: 'metasearcher',
        },
        {
          title: 'News',
          titulo: 'Noticias',
          url: news,
          route: 'news',
          description: 'Recent and relevant global news.',
          icon: newsIcon,
          type: 'metasearcher',
        },
        {
          title: 'Media',
          titulo: 'Media',
          url: media,
          route: 'media',
          description: 'Rich instructional videos',
          icon: instructionalVideosIcon,
          type: 'metasearcher',
        },
      ],
    },
    {
      type: 'independient',
      data: [
        {
          title: 'Job Hunter',
          titulo: 'Empleos',
          url: job,
          route: 'jobs',
          description: 'More than 2k job offers',
          icon: jobsIcon,
          type: 'independient',
        },
        {
          title: 'Interesting Links',
          titulo: 'Enlaces de interés',
          url: interestingLinks,
          route: 'interesting-links',
          description: 'Interesting Links',
          icon: interestingLinksIcon,
          type: 'independient',
        },
        {
          title: 'Soft skills and American life',
          titulo: 'Habilidades y vida americana',
          url: softSkillsSection,
          route: 'american-life',
          description: 'Learn about Soft Skills and American life',
          icon: americanLifeIcon,
          type: 'independient',
        },
        {
          title: 'Tutorials',
          titulo: 'Tutoriales',
          url: '',
          route: 'tutorials',
          description: 'Turotials',
          icon: interestingLinksIcon,
          type: 'independient',
        },
        {
          title: 'Help',
          titulo: 'Ayuda',
          url: '',
          route: 'help',
          description: 'Help',
          icon: helpIcon,
          type: 'independient',
        },
        {
          title: 'Search',
          titulo: 'Busqueda',
          url: '',
          route: 'search',
          description: 'Search',
          icon: '',
          type: 'none',
        },
      ],
    },
  ];

  institutionCode !== 'UTH' &&
    sections[0].data.push({
      title: 'Books',
      titulo: 'Libros',
      url: books,
      route: 'books',
      description: '16k+ books results',
      icon: articlesIcon,
      type: 'metasearcher',
    });

  ebooks &&
    sections[0].data.push({
      title: 'EBooks',
      titulo: 'EBooks',
      url: eBooks,
      route: 'ebooks',
      description: 'Ebooks',
      icon: ebookIcon,
      type: 'other',
    });

  sections[0].data.push({
    title: 'Aquino AI',
    titulo: 'Aquino IA',
    url: iaSection,
    route: 'ai',
    description: 'Aquino AI',
    icon: iaIcon,
    type: 'other',
  });

  (institutionCode === 'MTA' ||
    institutionCode === 'DEMO' ||
    institutionCode === 'LIBRARIAN') &&
    sections[1].data.unshift({
      title: 'Art',
      titulo: 'Arte',
      url: art,
      route: 'art',
      description: 'More than 10k art stories',
      icon: artIcon,
      type: 'independient',
    });

  institutionCode === 'MACKENZIE' &&
    sections[1].data.unshift({
      title: 'Library Video',
      titulo: 'Videos de la biblioteca',
      url: art,
      route: 'video-library',
      description: 'Library videos',
      icon: videoLibraryIcon,
      type: 'independient',
    });

  return sections;
};
