import { fetchWithoutToken } from '../utils/fetch';

// Este endpoint se usa para la sección de Jobs
export const getJobs = async (
  query,
  page = 1,
  remote = 0,
  institutionCode = '',
  next_page = ''
) => {
  let url = `/jobs/search?query=${query}&page=${page}&only_remote=${remote}&lang=en`;
  url = page > 1 ? `${url}&next_page=${next_page}` : url;

  url = institutionCode === 'ILUNI' ? `${url}&geo=gt` : `${url}&geo=us`;

  try {
    const res = await fetchWithoutToken.get(url, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    });

    return res;
  } catch (error) {
    console.error(error);
  }
};

export const getJobsApply = async (id) => {
  try {
    const res = await fetchWithoutToken.get(`/jobs?id=${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.token}`,
      },
    });
    return res;
  } catch (error) {
    console.error(error);
  }
};
