import React from 'react';
import { Link } from 'react-router-dom';
// Component
import Navbar from '../Navbar';
import Login from '../Login';
import Container from '../Container';
// Images
import logoHeaderHome from '../../Images/logo/logo_OlibWhite.svg';
// Style
import './Header.css';

const Header = ({ institutionCode }) => {
  return (
    <header className='header'>
      <Container>
        <div className='headerContainer'>
          <div className='headerContainer__logo'>
            <Link to='/'>
              <img
                className='navigationList__linkToHome'
                src={logoHeaderHome}
                alt='logo'
              />
            </Link>
            {institutionCode === 'ILUNI' && (
              <img
                className='logoInstitution'
                src='https://institutions-assets.s3.us-east-2.amazonaws.com/logo/ILUNI_logo.png'
                alt='logo institution'
              />
            )}
          </div>

          <div className='navbarAndLoginContainer'>
            <Navbar type='white' />
            <Login />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
