import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// Components
import Footer from '../../Components/Footer';
import Loading from '../../Components/Loading';
import Container from '../../Components/Container';
// Images
import aquinoLogin from '../../Images/logo/logo_OlibBlack.svg';
// Icons
import { HiOutlineUser } from 'react-icons/hi';
import { RiLockPasswordLine } from 'react-icons/ri';
import { IoChevronBackOutline } from 'react-icons/io5';
// Styles
import './Recover.css';
import {
  getRecoverPassword,
  getValidateSecurityCode,
  getNewPassword,
} from '../../services/sendRecoverUser.js';

const Recover = () => {
  // Variables
  const [step, setStep] = useState('initial'); // Modify steps
  // Form Variables
  const [emailForm, setEmailForm] = useState(''); // initial step
  const [codeForm, setCodeForm] = useState(''); // code step
  const [passForm, setPassForm] = useState(''); // password step
  // Process variables
  const [successInRecover, setSuccessInRecover] = useState(false);
  const [errorInRecover, setErrorInRecover] = useState(false);
  // Loading variable
  const [loading, setLoading] = useState(false);

  // Function to show error message
  const showError = () => {
    setErrorInRecover(true);
    setTimeout(() => {
      setErrorInRecover(false);
    }, '5000');
  };

  // Function to show success message
  const ShowSuccess = () => {
    setSuccessInRecover(true);
    setTimeout(() => {
      setSuccessInRecover(false);
      setStep('initial');
      setEmailForm('');
      setCodeForm('');
      setPassForm('');
    }, '5000');
  };

  // Process inicial step
  const submitInitialStep = (email) => {
    setLoading(true);
    getRecoverPassword(email)
      .then((response) => {
        if (response.status === 200) {
          setStep('code');
          setLoading(false);
        } else {
          setLoading(false);
          showError();
        }
      })
      .catch((error) => {
        setLoading(false);
        showError();
        console.error(error);
      });
  };
  // Process security code step
  const submitCodeStep = (email, code) => {
    setLoading(true);
    getValidateSecurityCode(email, code)
      .then((response) => {
        if (response.status === 200) {
          setStep('password');
          setLoading(false);
        } else {
          setLoading(false);
          showError();
        }
      })
      .catch((error) => {
        setLoading(false);
        showError();
        console.error(error);
      });
  };

  // Process change password step
  const submitPasswordStep = (email, code, pass) => {
    setLoading(true);
    getNewPassword(email, code, pass)
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          ShowSuccess();
        } else {
          setLoading(false);
          showError();
        }
      })
      .catch((error) => {
        setLoading(false);
        showError();
        console.error(error);
      });
  };

  // doe submot function for each step
  const handleSubmit = (step) => {
    switch (step) {
      case 'initial':
        submitInitialStep(emailForm);
        return null;
      case 'code':
        submitCodeStep(emailForm, codeForm);
        return null;
      case 'password':
        submitPasswordStep(emailForm, codeForm, passForm);
        return null;
      default:
        return null;
    }
  };

  // Function to render different steps
  const renderStep = (step) => {
    if (step === 'initial') {
      return (
        <div className='formRecover__inputs'>
          <div className='input-container'>
            <label>Email</label>
            <input
              onChange={(e) => setEmailForm(e.target.value)}
              type='email'
              name='name'
              placeholder='Email'
              required
              value={emailForm}
            />
            <HiOutlineUser />
          </div>
        </div>
      );
    } else if (step === 'code') {
      return (
        <div className='formRecover__inputs'>
          <div className='input-container'>
            <label>Authorization code</label>
            <input
              onChange={(e) => setCodeForm(e.target.value)}
              type='password'
              name='name'
              placeholder='Code'
              required
              value={codeForm}
            />
            <RiLockPasswordLine />
          </div>
        </div>
      );
    } else if (step === 'password') {
      return (
        <div className='formRecover__inputs'>
          <div className='input-container'>
            <label>New password</label>
            <input
              onChange={(e) => setPassForm(e.target.value)}
              type='password'
              name='name'
              placeholder='Password'
              required
              value={passForm}
            />
            <RiLockPasswordLine />
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  // Function to get text for different steps
  const getTextProcess = (step) => {
    if (step === 'initial') {
      return {
        info: 'Verify your identity: enter your email to receive your code.',
        button: 'Receive Code',
      };
    } else if (step === 'code') {
      return {
        info: `Enter the verification code sent to your email ${emailForm}`,
        button: 'Validate',
      };
    } else if (step === 'password') {
      return {
        info: `Enter the new password for your account`,
        button: 'Change password',
      };
    }
  };

  return (
    <main className='recoverPage'>
      <Container className='recoverPageContainer'>
        <Link to='/' className='backLoginButton'>
          <IoChevronBackOutline />
          Return
        </Link>
        <div className='recoverContainer'>
          <Link to='/'>
            <img src={aquinoLogin} alt='Aquino' />
          </Link>

          <p className='info'>{getTextProcess(step).info}</p>

          {loading ? (
            <Loading />
          ) : (
            <form
              className='formRecover'
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(step);
              }}
            >
              {renderStep(step)}
              <button className='btnLogin' type='submit'>
                {getTextProcess(step).button}
              </button>
            </form>
          )}

          {successInRecover && (
            <p className='sucessMessage'>
              Your new password has been successfully changed. Your new user
              credentials will be sent to your email.
            </p>
          )}
          {errorInRecover && (
            <p className='errorMessage'>
              An error occurred during the process. Please try again.
            </p>
          )}
        </div>
        <section className='infoSectionContrainer'>
          <Link to={'/login'} className='aux-text'>
            Already have an account?
          </Link>
          <Link to={'/signup'} className='aux-text'>
            Need to create an account?
          </Link>
        </section>
      </Container>
      <Footer allContent={false} />
    </main>
  );
};

export default Recover;
