// Utils
import { fetchWithoutToken } from '../utils/fetch';

// Function to send chat information to librarian
export const sendChatInformation = async (
  { description, email, fullName, phone, title },
  institution,
  type,
  urgency,
  to_email
) => {
  try {
    const res = await fetchWithoutToken.post(
      '/institutions/send/mail',
      {
        contactInfo: {
          description,
          email,
          fullName,
          phone,
          title,
        },
        institution,
        type,
        urgency,
        to_email,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    return res;
  } catch (error) {
    return error;
  }
};
